<template>
  <div id='app' class='app'>
    <div class="LogoContainer" id="LogoContainer">
      <img src="../public/LogoPayasos.gif" class="LogoGif" v-on:load="TimeoutFunc">
    </div>
    <nav id='nav'>
      <section id="Menu" class="show-nav">
      <section class="MenuBody">
        <section class="MenuTextOptions">
          <div class="ButtonMenuContainer">
            <span v-on:click="ButtonMenu" id="MenuButton2"><b><svg class="icon icon-cross2" id="icon-cross2"><use xlink:href="#icon-wink"></use></svg></b></span>
          </div>
          <div class="wrap" id="wrapmenu">
            <span v-on:click="ButtonFInfan"><a style="display: none"></a><a style="display: none"></a><a>Fiestas Infantiles&#128102;</a></span>
            <span v-on:click="ButtonPayasos"><a style="display: none"></a><a style="display: none"></a><a>Payasos &#129313;</a></span>
            <span v-on:click="ButtonMagia"><a style="display: none"></a><a style="display: none"></a><a>Magia &#127913;</a></span>
            <span v-on:click="ButtonMimos"><a style="display: none"></a><a style="display: none"></a><a>Mimos &#127917;</a></span>
            <span v-on:click="ButtonMalabares"><a style="display: none"></a><a style="display: none"></a><a>Malabares &#129337;</a></span>
          </div>
        </section>
      </section>
    </section>
    </nav>
    <div id="TopHeaderThing"> 
      <div id="TopHLeft">
        <p class="TextTop"> &#128222; Llamanos: +57 313 3434210 - +57 314 4554455</p>
      </div>
      <div id="TopHRight">
        <svg class="icon icon-facebook2">
          <symbol id="icon-facebook2" viewBox="0 0 32 32">
            <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"></path>
          </symbol>
          <symbol id="icon-instagram" viewBox="0 0 32 32">
            <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"></path>
            <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"></path>
            <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"></path>
          </symbol>
          <symbol id="icon-youtube" viewBox="0 0 32 32">
            <path d="M31.681 9.6c0 0-0.313-2.206-1.275-3.175-1.219-1.275-2.581-1.281-3.206-1.356-4.475-0.325-11.194-0.325-11.194-0.325h-0.012c0 0-6.719 0-11.194 0.325-0.625 0.075-1.987 0.081-3.206 1.356-0.963 0.969-1.269 3.175-1.269 3.175s-0.319 2.588-0.319 5.181v2.425c0 2.587 0.319 5.181 0.319 5.181s0.313 2.206 1.269 3.175c1.219 1.275 2.819 1.231 3.531 1.369 2.563 0.244 10.881 0.319 10.881 0.319s6.725-0.012 11.2-0.331c0.625-0.075 1.988-0.081 3.206-1.356 0.962-0.969 1.275-3.175 1.275-3.175s0.319-2.587 0.319-5.181v-2.425c-0.006-2.588-0.325-5.181-0.325-5.181zM12.694 20.15v-8.994l8.644 4.513-8.644 4.481z"></path>
          </symbol>
          <symbol id="icon-cross" viewBox="0 0 32 32">
            <path d="M0.292 6.292c0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.048 0.252-0.124 0.357-0.228 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.104 0.227 0.18 0.357 0.228 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.361-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.104-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.049-0.252 0.124-0.357 0.229-0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.229-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.362 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357z"></path>
          </symbol>
          <symbol id="icon-happy" viewBox="0 0 32 32">
            <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zM16 18.711c3.623 0 7.070-0.963 10-2.654-0.455 5.576-4.785 9.942-10 9.942s-9.544-4.371-10-9.947c2.93 1.691 6.377 2.658 10 2.658zM8 11c0-1.657 0.895-3 2-3s2 1.343 2 3c0 1.657-0.895 3-2 3s-2-1.343-2-3zM20 11c0-1.657 0.895-3 2-3s2 1.343 2 3c0 1.657-0.895 3-2 3s-2-1.343-2-3z"></path>
          </symbol>
          <symbol id="icon-home3" viewBox="0 0 32 32">
            <path d="M32 19l-6-6v-9h-4v5l-6-6-16 16v1h4v10h10v-6h4v6h10v-10h4z"></path>
          </symbol>
          <symbol id="icon-wink" viewBox="0 0 32 32">
            <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zM16.961 22.22c4.383-0.866 7.785-2.861 9.014-5.519-0.677 5.249-5.047 9.299-10.339 9.299-3.726 0-6.996-2.009-8.84-5.030 2.2 1.721 6.079 2.056 10.165 1.249zM20 11c0-1.657 0.895-3 2-3s2 1.343 2 3c0 1.657-0.895 3-2 3s-2-1.343-2-3zM11 11.609c-1.306 0-2.417 0.489-2.829 1.172-0.111-0.183-0.171-1.005-0.171-1.211 0-0.971 1.343-1.758 3-1.758s3 0.787 3 1.758c0 0.206-0.061 1.028-0.171 1.211-0.412-0.683-1.522-1.172-2.829-1.172z"></path>
          </symbol>
        </svg>
          <div><a href="https://www.facebook.com/payasos.jumbo.5" target="_blank"><svg class="icon icon-facebook2"><use xlink:href="#icon-facebook2"></use></svg></a></div>
          <div><a href="https://www.instagram.com/circojumbosanteiros/" target="_blank"><svg class="icon icon-instagram"><use xlink:href="#icon-instagram"></use></svg></a></div>
          <div><a href="https://www.youtube.com/channel/UC_JMuILyuRn8cwkagytCr0g/videos" target="_blank"><svg class="icon icon-youtube"><use xlink:href="#icon-youtube"></use></svg></a></div>
      </div> 
    </div>
    <header>
      <div id="HeaderLeftThing">
        <img src="../public/LogoCirco.png" class="HomeButton" v-on:click="ButtonInicio">
      </div>

      <div id="HeaderRightThing" class="wrap">
        <span v-on:click="ButtonInicio"><a style="display: none"></a><a style="display: none"></a><a class="IconHomeBox" style="padding: 20px 20px 10px 20px"><svg class="icon icon-home3" ><use xlink:href="#icon-home3"></use></svg></a></span>
        <span v-on:click="ButtonServicios"><a style="display: none"></a><a style="display: none"></a><a>&#127881; Servicios</a></span>
        <span v-on:click="ButtonNosotros"><a style="display: none"></a><a style="display: none"></a><a>&#127914; Nosotros</a></span>
        <span v-on:click="ButtonContacto"><a style="display: none"></a><a style="display: none"></a><a>&#128232; Contacto</a></span>
        <span v-on:click="ButtonMenu" id="MenuButton"><b><svg class="icon icon-cross"><use xlink:href="#icon-happy"></use></svg></b></span>
      </div>
      <div class="WhatsappContainer">
        <!-- https://wa.me/57-->
        <a href="https://wa.me/57313 3434210" target="_blank"><img src="../public/Whatsapp.png" class="Whatsapp" id="Whatsapp"></a>
      </div>  
    </header>
    <router-view
    v-on:ButtonPayasos="ButtonPayasos"
    v-on:ButtonMimos="ButtonMimos"
    v-on:ButtonShowsInf="ButtonFInfan"
    v-on:ButtonMagia="ButtonMagia"
    v-on:ButtonConocenos="ButtonNosotros"
    v-on:ButtonContactanos="ButtonContacto"
    v-on:ButtonMalabares="ButtonMalabares">
    </router-view>
    <footer>
      <div class="Panel">
        <div class="PanelLeft">
          <p class="TextTop"> &#128222; Llamanos: +57 313 3434210 - +57 314 4554455</p>
        </div>
        <div class="PanelRight" id="Redes">
          <div><a href="https://www.facebook.com/payasos.jumbo.5" target="_blank"><svg class="icon icon-facebook2"><use xlink:href="#icon-facebook2"></use></svg></a></div>
          <div><a href="https://www.instagram.com/circojumbosanteiros/" target="_blank"><svg class="icon icon-instagram"><use xlink:href="#icon-instagram"></use></svg></a></div>
          <div><a href="https://www.youtube.com/channel/UC_JMuILyuRn8cwkagytCr0g/videos" target="_blank"><svg class="icon icon-youtube"><use xlink:href="#icon-youtube"></use></svg></a></div>
        </div>
      </div>
    </footer>

  </div>
</template>
<script>
import '../public/style.scss'
export default {
  name: "App",
  data: function(){

  },
  methods:{
    TimeoutFunc: function() {
      setTimeout(this.LoadAppPage, 5000)
    },
    ButtonContacto: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Contactanos"});
    },
    CerrarMenu: function() {
      if (!(document.querySelector("#Menu").classList.contains('show-nav'))) {
          document.querySelector("#Menu").classList.add('show-nav');
          (document.querySelector("#Menu").classList.remove('showing-nav'));
          document.querySelector("#Menu").classList.add('dontshow-nav');
          document.getElementById("MenuButton").style.transition = "0.5s ease all";
          document.getElementById("MenuButton").style.transform = "rotate(0deg)";
      }
    },
    LoadAppPage: function() {
      document.getElementById("LogoContainer").style.display = "none";
    },
    ButtonFInfan: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "FiestasInf"});
    },
    ButtonPayasos: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Payasos"});
    },
    ButtonMagia: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Magia"});
    },
    ButtonMimos: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Mimos"});
    },
    ButtonServicios: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Servicios"});
    },
    ButtonNosotros: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Nosotros"});
    },
    ButtonMalabares: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
      this.$router.push({name: "Malabares"});
    },
    ButtonOpcion3: function() {
      this.CerrarMenu();
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000)
    },
    ButtonMenu: function(){
      if (document.querySelector("#Menu").classList.contains('show-nav')) {
          // Do things on Nav Close
          (document.querySelector("#Menu").classList.remove('show-nav'));
          (document.querySelector("#Menu").classList.remove('dontshow-nav'));
          document.querySelector("#Menu").classList.add('showing-nav');
          document.getElementById("MenuButton").style.transition = "0.5s ease all";
          document.getElementById("MenuButton").style.transform = "rotate(60deg)";
      } else {
          // Do things on Nav Open
          document.querySelector("#Menu").classList.add('show-nav');
          (document.querySelector("#Menu").classList.remove('showing-nav'));
          document.querySelector("#Menu").classList.add('dontshow-nav');
          document.getElementById("MenuButton").style.transition = "0.5s ease all";
          document.getElementById("MenuButton").style.transform = "rotate(0deg)";
      }
    },
    ButtonInicio: function() {
      this.CerrarMenu();
      this.$router.push({name: "Home"});
      document.getElementById("LogoContainer").style.display = "flex";
      setTimeout(this.LoadAppPage, 5000);
      this.$router.push({name: "Home"});
    }
  },
  created: function(){
    this.$router.push({name: "Home"});
  }
}
</script>
<style>
#Redes {
  display: inline-flex;
}
.WhatsappContainer {
  position: fixed;
  display: flex;
  bottom: 20px;
  right: 20px;
  width: 10%;
  height: 20%;
}
.Whatsapp {
  width: 100%;
  height: 100%;
}
/**
  Colors: #ffe3b7
          #840404
          #cb0101
          #ffaf00
 */
.LogoGif {
  border-radius: 100%;
  width: 70%;
}
.LogoContainer {
  transition-delay: 10s;
  margin: -75px 0px 0px 0px;
  z-index: 99;
  background-color: #831b00;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
body {
  height: 100%;
  min-height: 100vh;
  max-height: fit-content;
  margin: 0px;
  padding: 75px 0px 0px 0px;
}
#app {
  height: max-content;
  margin: 0px;
  padding: 0px;
  font-family: 'Fredoka One', sans-serif;
  text-align: center;
  color: #840404;
}
header {
  z-index: 97;
  top: 50px;
  background-color: #FFFFFF;
  position: fixed;
  margin: 0px;
  display: flex;
  width: 100%;
  height: 100px;
  -webkit-box-shadow: 0px 10px 13px -12px #000000, 0px 0px 0px -30px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -12px #000000, 0px 0px 0px -30px rgba(0,0,0,0);
}
#TopHLeft{
  width: 50%;
  padding: 0px 30px;
  text-align: right;
  align-self: center;
}
.TextTop {
  color: #FFFFFF;
}
#TopHRight {
  padding: 0px 50px;
  display: inline-flex;
  width: 50%;
  justify-content: center;
  align-self: center;
}
.icon {
  cursor: pointer;
  margin: 0px 20px;
  display: inline-flex;
  width: 30px;
  height: 30px;
  stroke-width: 0;
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.icon-instagram {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.icon-youtube {
  margin: 0px 30px 0px 20px;
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.icon-home3 {
  display: inline-flex;
  margin: 0px;
  stroke: #840404;
  fill: #840404;
  transition: fill 0.3s;
}
.IconHomeBox:hover svg{
  stroke: #cb0101;
  fill: #cb0101;
}
#HeaderLeftThing {
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 100px 10px 10px 10px;
  display: flex;
  width: 30%;
}
#HeaderRightThing {
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
  padding: 10px 40px;
  display: flex;
  width: 70%;
}
#TopHeaderThing {
  z-index: 97;
  height: 50px;
  margin: 0px;
  padding: 0px 10px;
  top: 0px;
  background-color: #840404;
  position: fixed;
  margin: 0px;
  display: flex;
  width: 100%;
}
.HomeButton {
  cursor: pointer;
  width: 275px;
  height: 200px;
  transition: all 0.3s;
}
.HomeButton:hover {
  width: 350px;
  height: 250px;
}
.icon-cross {
  box-sizing: unset;
  stroke: #840404;
  fill: #840404;
  padding: 10px 20px;
  transition: 1s ease all;
  width: 3em;
  height: 3em;
}
.icon-cross2 {
  width: 3em;
  height: 3em;
  top: 12%;
  position: absolute;
  right: 37%;
  box-sizing: unset;
  stroke: #FFFFFF;
  fill: #FFFFFF;
  padding: 0px;
  transition: 1s ease all;
  z-index: 99;
  margin: 0px;
}
/* .icon-cross:hover {
  stroke: #cb0101;
  fill: #cb0101;
  transform: rotate(-44deg);
  padding: 10px 20px;
} */
span {
  margin: 0px 20px;
  cursor: pointer;
  padding: 0px;
}
span a {
  transition: color 0.5s;
} 
span:hover a{
  color:#cb0101;
}
footer {
  display: none;
  background-color: #840404;
  width: 100%;
  height: fit-content;
}
.show-nav .MenuBody {
  transition: 1s ease all;
  -webkit-transform: translateX(95vw);
  transform: translateX(95vw);
  -webkit-transform: translate3d(95vw, 0, 0);
  transform: translate3d(95vw, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; 
  z-index: 99;
}
.MenuBody {
  color: #f3cba5;
  transition: 0.5s ease all;
  background-color: #840404;
  height: 100%;
  width: 100%;
  right: 0px;
  position: absolute;
  z-index: 99;
}
.MenuBody span a{
  color: #f3cba5;
}
#Menu {
  top: 0;
  right: 0;
  position: fixed;
  overflow: hidden;
  display: flex;
  width: 20%;
  height:-webkit-fill-available;
}
.dontshow-nav {
  transition-delay: 0.5s;
  z-index: 0;
}
.showing-nav {
  z-index: 99;
}
.ButtonMenuContainer {
  top: 12%;
  position: absolute;
  width: 100%;
  height: 40px;
}
#wrapmenu {
  top: 20%;
  position: absolute;
}
#wrapmenu span {
  width: 100%;
  margin: 20px 0px;
}
#wrapmenu span a {
  font-size: 20px;
}
</style>
