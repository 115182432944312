<template>
    <div class="main-component">
        <div class="HomeVideoBoxContainer" id="HomeVideoBoxContainer">
            <div class="HomeVideoContainer">
                <span v-on:click="ButtonCloseVideoContainer" id="CloseButton"><b><svg class="icon icon-cross3"><use xlink:href="#icon-cross"></use></svg></b></span>
                <center><video id="videoHome" src="HomeVideo.mp4" width="900" height="450" loop autoplay controls v-on:load="LoadHome"></video></center>
            </div>
        </div>
        <body>
            <div class="Banner">
                <img src="../../public/globo.png" class="globo1" id="globo1">
                <img src="../../public/globo3.png" class="globo4" id="globo4">
                <div id="BannerPanelLeft">
                    <img src="../../public/Payaso1.png" class="Payaso1 DontShowPayaso1" id="Payaso1" v-on:load="MostrarPayasos">
                    <img src="../../public/Payaso2.png" class="Payaso2" id="Payaso2">
                </div>
                <div class="malabaristaContainer">
                    <img src="../../public/Malabarista.png" class="Malabarista" id="Malabarista">
                    <img src="../../public/aro1.png" class="aro1" id="aro1">
                    <img src="../../public/aro2.png" class="aro2" id="aro2">
                    <img src="../../public/aro3.png" class="aro3" id="aro3">
                </div>
                <div id="BannerPanelRight" class="BannerPanelRight">
                    <div class="c-subscribe-box u-align-center">
                        <div class="rainbow"><span></span><span></span></div>
                        <div class="c-subscribe-box__wrapper">
                            <h3 class="c-subscribe-box__title">&#127880; ¡HACEMOS QUE TU FIESTA SEA LA MÁS DIVERTIDA! &#127881;</h3>
                            <div class="wrap" style="margin: 20px 0px; justify-content: center">
                                <span v-on:click="ButtonConocenos"><a style="display: none"></a><a style="display: none"></a><a><b style="color: #5e5e5e">CONOCENOS! &#128588;</b></a></span>
                            </div>    
                        </div>
                    </div>
                </div>
                <img src="../../public/globo.png" class="globo2" id="globo1">
                <img src="../../public/globo2.png" class="globo3" id="globo2">
            </div>
            <div class="Panel">
                <div class="PanelLeft">
                    <h1>Fiestas Infantiles &#128102; &#128103;</h1>
                    <p>Llevamos el circo a tu fiesta infantil con divertidos juegos y shows circenses para toda la familia.</p>
                    <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasShows"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
                </div>
                <div class="PanelRight PanelRightShows" id="PanelImagen">
                    <img src="../../public/5.jpg" class="ImgAbajo">
                    <img src="../../public/3.jpg" class="ImgArriba">
                </div>
            </div>
            <div class="Panel">
                <div class="PanelLeft" id="PanelImagen">
                    <img src="../../public/3.jpg" class="ImgAbajo">
                    <img src="../../public/8.jpg" class="ImgArriba">
                </div>
                <div class="PanelRight">
                    <h1>Payasos &#129313;</h1>
                    <p>Espectacular caravana de payasos, hará que tu fiesta sea la más divertida.</p>
                    <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasPayasos"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
                </div>
            </div>
            <div class="Panel">
                <div class="PanelLeft">
                    <h1>Magia &#127913;</h1>
                    <p>El espectacular mundo del ilusionismo de habilidad e ingenio con este maravilloso show todos quedarán asombrados.</p>
                    <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMagia"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
                </div>
                <div class="PanelRight" id="PanelImagen">
                    <img src="../../public/5.jpg" class="ImgAbajo">
                    <img src="../../public/9.png" class="ImgArriba" id="ImgMagia">
                </div>
            </div>
            <div class="Panel">
                <div class="PanelLeft" id="PanelImagen">
                    <img src="../../public/4.jpg" class="ImgAbajo">
                    <img src="../../public/2.jpg" class="ImgArriba">
                </div>
                <div class="PanelRight">
                    <h1>Mimos &#127917;</h1>
                    <p>La finalidad del mimo se podría resumir en una frase: “Hacer visible lo invisible”. Y animar tu fiesta ¡Claro!</p>
                    <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMimos"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
                </div>
            </div>
            <div class="Panel">
                <div class="PanelLeft">
                    <h1>Malabares &#129337;</h1>
                    <p>El arte de manipular y ejecutar un espectáculo con objetos en el aire, con este grandioso show todos quedarán fascinados.</p>
                    <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMalab"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
                </div>
                <div class="PanelRight" id="PanelImagen">
                    <img src="../../public/3.jpg" class="ImgAbajo">
                    <img src="../../public/10.jpg" class="ImgArriba">
                </div>
            </div>
        </body>
    </div>
</template>

<script>
export default {
    name: "Home",
    data: function(){  

    },
    methods:{
        LoadHome: function() {
            document.getElementById("HomeVideoBoxContainer").style.display = "flex";
            document.getElementById("videoHome").play();
        },
        ButtonVerMasShows: function() {
            this.$emit('ButtonShowsInf');
        },
        ButtonVerMasMagia: function() {
            this.$emit('ButtonMagia');
        },
        ButtonVerMasPayasos: function() {
            this.$emit('ButtonPayasos');
        },
        ButtonVerMasMimos: function() {
            this.$emit('ButtonMimos');
        },
        ButtonConocenos: function() {
            this.$emit('ButtonConocenos');
        },
        ButtonVerMasMalab: function() {
            this.$emit('ButtonMalabares');
        },
        handleScroll (event) {
            
        },
        ButtonCloseVideoContainer: function() {
            document.getElementById("HomeVideoBoxContainer").style.display = "none";
            document.getElementById("Payaso1").style.margin = "0px 0px 0px 0px";
            document.getElementById("Payaso1").style.opacity = "100";
            document.getElementById("Payaso2").style.margin = "10px 0px 0px 0px";
            document.getElementById("Payaso2").style.opacity = "100";
            document.getElementById("Malabarista").style.opacity = "100";
            document.getElementById("aro1").style.opacity = "100";
            document.getElementById("aro2").style.opacity = "100";
            document.getElementById("aro3").style.opacity = "100";
            document.getElementById("BannerPanelRight").style.opacity = "100";
            document.getElementById("BannerPanelRight").style.transform = "translate(0px, 0px)";
        }
    },
    created: function(){
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style>
.icon-cross3 {
  top: 15%;
  position: absolute;
  right: 15%;
  box-sizing: unset;
  stroke: #FFFFFF;
  fill: #FFFFFF;
  padding: 0px;
  transition: 1s ease all;
  z-index: 99;
  margin: 0px;
}
.HomeVideoBoxContainer {
    margin: -70px 0px 0px 0px;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.HomeVideoContainer {
    align-items: center;
    border-radius: 15px;
    background-color: #840404;
    width: 1000px;
    height: 500px;
    z-index: 99;
}
.divtextbanner {
    display: flex;
}
.BannerPanelRight {
    transform: translate(350px, 0px);
    opacity: 0;
}
#BannerPanelRight {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; 
    transition: transform 2s, opacity 2s;
    padding: 30px 0px 30px 80px;
    height: 500px;
    text-align: center;
    align-items: center;
    right: 30px;
    display: flex;
    width: 50%;
}
.PanelLeft {
    padding: 10px 20px 10px 200px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.PanelRight {
    padding: 10px 200px 10px 20px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    width: 50%;
}
#BannerPanelLeft {
    justify-content: flex-start;
    padding: 70px 30px;
    height: 500px;
    text-align: center;
    align-items: center;
    display: flex;
    width: 50%;
}
.globo4 {
    position: absolute;
    display: flex;
    width: 8%;
    align-self: flex-start;
    left: 5%;
    margin: 100px 0px 0px 0px;
    transform: rotate(-20deg);
    transition: transform 1s, opacity 1s;
}
.globo1 {
    position: absolute;
    display: flex;
    width: 8%;
    align-self: flex-end;
    left: 5%;
    margin: 50px 0px 0px 0px;
    transition: transform 1s, opacity 1s;
}
.globo2 {
    transform: rotate(-20deg);
    position: absolute;
    display: flex;
    width: 8%;
    align-self: flex-start;
    right: 5%;
    margin: 100px 0px 0px 0px;
    transition: transform 1s, opacity 1s;
}
.globo3 {
    transform: rotate(20deg);
    position: absolute;
    display: flex;
    width: 8%;
    align-self: flex-end;
    right: 5%;
    /* margin: 100px 0px 0px 0px; */
    transition: transform 1s, opacity 1s;
}
.globo3:hover {
    transform: translate(0px, -200px);
    opacity: 0;
}
.globo2:hover {
    transform: translate(0px, -200px);
    opacity: 0;
}
.globo1:hover {
    transform: translate(0px, -200px) rotate(40deg);
    opacity: 0;
}
.globo4:hover {
    transform: translate(0px, -200px) rotate(-40deg);
    opacity: 0;
}
.Payaso1 {
    left: 12%;
    position: absolute;
    margin: -400px 0px 0px 0px;
    transition: margin 2s, width 0.3s, opacity 2s;
    vertical-align: top;
    width: 14%;
    opacity: 0;
}
.Malabarista {
    transition: width 1s, opacity 2s;
    position: absolute;
    left: 40%;
    margin: 200px 0px 0px 0px;
    vertical-align: bottom;
    width: 14%;
    opacity: 0;
    z-index: 96;
}
.malabaristaContainer:hover .aro1{
    transform: translateY(-300px) rotate(1250deg);
}
.malabaristaContainer:hover .aro2{
    transform: translateY(-200px) rotate(-1250deg);
}
.malabaristaContainer:hover .aro3{
    transform: translateY(-350px) rotate(1250deg);
}
.malabaristaContainer:hover .Malabarista{
    width: 16%;
    position: absolute;
}
.c-subscribe-box:hover{
    -webkit-box-shadow: 0px 0px 10px 8px hsla(335, 75%, 64%, 0.5); 
    box-shadow: 0px 0px 10px 8px hsla(335, 75%, 64%, 0.5);
}
.aro1 {
    transition: all 2s;
    position: absolute;
    left: 48%;
    margin: 200px 0px 0px 0px;
    vertical-align: bottom;
    width: 8%;
    z-index: 95;
    opacity: 0;
}
.aro2 {
    transition: all 2s;
    position: absolute;
    left: 50%;
    margin: 400px 0px 0px 0px;
    vertical-align: bottom;
    width: 8%;
    z-index: 95;
    opacity: 0;
}
.aro3 {
    transition: all 2s;
    position: absolute;
    left: 50%;
    margin: 400px 0px 0px 0px;
    vertical-align: bottom;
    width: 8%;
    z-index: 95;
    opacity: 0;
}
.Payaso1:hover {
    width: 15%;
    position: absolute;
}
.Payaso2:hover {
    width: 15%;
    position: absolute;
}
.Payaso2 {
    left: 25%;
    position: absolute;
    transition: margin 2s, width 0.3s,  opacity 2s;
    margin: 400px 0px 0px 0px;
    vertical-align: top;
    width: 14%;
    opacity: 0;
}
.Banner {
    overflow: hidden;
    font-family: 'Fredoka One', sans-serif;
    width: 100%;
    height: 500px;
    display: inline-flex;
    background-image: url("../../public/banner.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-color: #ffaf00; */
}
.Panel {
    margin: 20px 0px;
    font-family: 'Fredoka One', sans-serif;
    font-size: 20px;
    width: 100%;
    height: fit-content;
    display: inline-flex;
}
.ImgAbajo {
    margin: 10px 200px 10px 20px;
    position: absolute;
    transform: rotate(-5deg);
    width: 400px;
    height: 300px;
    transition: all 0.5s;
}
.ImgArriba {
    margin: 10px 200px 10px 20px;
    position: relative;
    transform: rotate(5deg);
    width: 400px;
    height: 300px;
    transition: all 0.5s;
}
.PanelRight:hover .ImgAbajo{
    transform: rotate(-10deg);
    width: 425px;
    height: 320px;
}
.PanelRight:hover .ImgArriba{
    transform: rotate(10deg);
    width: 425px;
    height: 320px;
}
.PanelLeft:hover .ImgAbajo{
    transform: rotate(-10deg);
    width: 425px;
    height: 320px;
}
.PanelLeft:hover .ImgArriba{
    transform: rotate(10deg);
    width: 425px;
    height: 320px;
}
@media screen and (max-width: 700px) {
    @-ms-viewport {
        width: 600px;
    }
    #LogoContainer {
        z-index: -50;
    }
    #LogoContainer img{
        display: none;
    }
    #videoHome {
        margin: 10vh 0px;
        width: 80vw;
        height: 50vw;
    }
    #HomeVideoBoxContainer {
        position: fixed;
        top: 0px;
        width: 100vw;
        height: 120vh;
    }
    .HomeVideoContainer {
        justify-self: center;
        align-self: center;
        width: 90vw;
        height: fit-content;
    }
    .icon-cross3 {
        top: 35%;
        width: 2em;
        height: 2em;
        font-size: 15px;
    }
    header {
        width: 100vw;
        font-size: 4vw;
        height: 75vw;
    }
    #HeaderRightThing {
        display: grid;
        padding: 0px;
        align-content: center;
        text-align: center;
    }
    #HeaderLeftThing {
        padding: 0px;
    }
    header span a {
        padding: 0px;
        margin: 0px;
    }
    header span {
        margin: 20px 0px;
    }
    #MenuButton2 {
        width: auto;
        height: fit-content;
    }
    header .icon {
        width: 2em;
        height: 2em;
    }
    header .IconHomeBox {
        display: none;
    }
    .icon {
        width: 4em;
        height: 4em;
    }
    .HomeButton {
        margin: 0px 0px 0px 20vw;
        width: 50vw;
        height: auto;
    }
    #Menu {
        width: 100vw;
    }
    .show-nav .MenuBody {
        transition: 1s ease all;
        -webkit-transform: translateX(100vw);
        transform: translateX(100vw);
        -webkit-transform: translate3d(100vw, 0, 0);
        transform: translate3d(100vw, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden; 
        z-index: 99;
    }
    #icon-cross2 {
        font-size: 30px;
    }
    #wrapmenu span a{
        font-size: 30px;
    }
    #wrapmenu {
        top: 30%;
    }
    #TopHeaderThing {
        display: none;
    }
    header {
        top: 0px;
    }
    body {
        width: 100vw;
        height: fit-content;
        padding: 140px 0px 0px 0px;
    }
    .main-component {
        width: 100vw;
        height: fit-content;
    }
    .Banner {
        background-size: cover;
        width: 100vw;
        height: 150vw;
    }
    #app {
        width: 100vw;
        height: fit-content;
    }
    .Panel {
        padding: 0px;
        margin: 0px;
        justify-items: center;
        align-items: center;
        display: grid;
        width: 100vw;
    }
    .PanelLeft {
        display: grid;
        margin: 20px 0px;
        padding: 0px;
    }
    .PanelRight {
        display: grid;
        margin: 20px 0px;
        padding: 0px;
    }
    .PanelRight:hover .ImgArriba{
        width: 75vw;
        height: 55vw;
    }
    .PanelRight:hover .ImgAbajo{
        width: 75vw;
        height: 55vw;
    }
    .PanelLeft:hover .ImgArriba{
        width: 75vw;
        height: 55vw;
    }
    .PanelLeft:hover .ImgAbajo{
        width: 75vw;
        height: 55vw;
    }
    .ImgArriba {
        margin: 0px;
        width: 70vw;
        height: 50vw;
    }
    .ImgAbajo {
        margin: 0px;
        width: 70vw;
        height: 50vw;
    }
    .Payaso1 {
        display: none;
    }
    .Malabarista {
        display: none;
    }
    .aro1 {
        display: none;
    }
    .aro2 {
        display: none;
    }
    .aro3 {
        display: none;
    }
    .Payaso2 {
        left: 3%;
        width: 50%;
    }
    .Payaso2:hover {
        width: 52%;
    }
    .globo1 {
        display: none;
    }
    .globo2 {
        display: none;
    }
    .globo3 {
        display: none;
    }
    .globo4 {
        display: none;
    }
    #BannerPanelRight {
        padding: 0px 10px 0px 0px;
        width: fit-content;
        height: 100%;
    }
    #BannerPanelRight .c-subscribe-box {
        width: 50vw;
        height: 50vw;
    }
    #BannerPanelRight .c-subscribe-box .c-subscribe-box__wrapper .c-subscribe-box__title{
        font-size: 5vw;
    }
    .WhatsappContainer {
        width: fit-content;
        height: fit-content;
    }
    .Whatsapp {
        width: 20vw;
        height: 20vw;
    }
    #PanelImagen {
        display: flex;
    }
    footer {
        height: fit-content;
        display: block;
        margin: 20px 0px 0px 0px;
    }
    #Redes {
        z-index: 99;
    }
}
</style>