<template>
<div class="main-component" id="bodyContactanos">
    <body style="height: fit-content; min-height: 0px;">
        <div class="Banner" id="BannerContactanos">
            <img src="../../public/globo.png" class="globo1" id="globo1" style="margin: 0px 0px 0px 0px;">
            <img src="../../public/globo3.png" class="globo4" id="globo4">
            <div class="c-subscribe-box u-align-center" id="SusContac">
                <div class="rainbow"><span></span><span></span></div>
                <div class="c-subscribe-box__wrapper" id="SusWrap">
                    <h3 class="c-subscribe-box__title">Contactanos</h3>
                    <form class="c-form c-form--accent c-subscribe-box__form" v-on:submit.prevent="enviarCorreo()">
                        <input class="u-align-center" type="text" v-model="correo.nombre" placeholder="Nombre"/>
                        <br>
                        <input class="u-align-center" type="email" v-model="correo.email" placeholder="Correo Electronico"/>
                        <br>
                        <textarea class="u-align-center" id="mensajebox" v-model="correo.mensaje" placeholder="Mensaje"/>
                        <br>
                        <input class="full-button" type="submit" value="Enviar"/>
                    </form>   
                </div>
            </div>
            <img src="../../public/globo.png" class="globo2" id="globo1">
            <img src="../../public/globo2.png" class="globo3" id="globo2"> 
        </div>
    </body>
</div>
</template>

<script>
export default {
    name: "Contactanos",
    data: function(){  
        return {
            correo: {
                nombre: "",
                email: "",
                mensaje: "",
            }
        }
    },
    methods:{
        enviarCorreo: function() {
            let msg = this.correo.mensaje + " \n Mi correo electronico es: " + this.correo.email;
            var url = 'https://formsubmit.co/ajax/4f4e367afeae01edc3f0151163100661';
            var data = {
                name: this.correo.nombre,
                message: msg
            };
            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
            .catch(error => alert('Error al enviar el mensaje', error))
            .then(response => alert('Mensaje enviado.'));
        },
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        }
    },
    created: function(){
    },
    destroyed () {
    }
}
</script>

<style>
h1 {
    margin: 0px;
}
#SusContac {
    border-radius: 20px;
    width: 510px; 
    height: 410px;
}
#SusWrap {
    border-radius: 20px; 
    width: 500px; height: 
    400px;
}
#mensajebox {
    height: 60%;
}
#bodyContactanos {
    display: flex;
    color: #5e5e5e;
    /* padding: 75px 0px 0px 0px; */
}
#BannerContactanos {
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 700px){
    #SusContac {
        border-radius: 20px;
        width: 70vw; 
        height: 95vw;
    }
    #SusWrap {
        border-radius: 20px; 
        width: 69vw; 
        height: 94vw;
    }
}
</style>