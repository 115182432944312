<template>
<div class="main-component">
    <body>
        <div class="Banner" id="BannerUniqueServ" style="justify-content: center;">
            <img src="../../public/globo.png" class="globo1" id="globo1">
            <img src="../../public/globo3.png" class="globo4" id="globo4">
            <center><video class="VideoPayasos" src="MALABAR.mp4" loop autoplay controls></video></center>
            <img src="../../public/globo.png" class="globo2" id="globo1">
            <img src="../../public/globo2.png" class="globo3" id="globo2"> 
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Malabares &#129337;</h1>
                <p>El arte de manipular y ejecutar un espectáculo con objetos en el aire, con este grandioso show todos quedarán fascinados.</p>
                <div class="wrap" id="wrapUnique" style="display: block"><span v-on:click="ButtonContactanos"><a style="display: none"></a><a style="display: none"></a><a>CONTACTANOS!</a></span></div>
            </div>
            <div class="PanelRight" id="PanelRMal">
                <img src="../../public/3.jpg" class="ImgAbajo">
                <img src="../../public/10.jpg" class="ImgArriba">
            </div>
        </div>
    </body>
</div>  
</template>

<script>
export default {
    name: "Malabares",
    data: function(){  

    },
    methods:{
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        },
    }
}
</script>

<style>
@media screen and (max-width: 700px) {
    #PanelRMal {
        display: flex;
    }
    
}
</style>