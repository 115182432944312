<template>
<div class="main-component" style="padding: 50px 0px 0px 0px">
    <body>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Fiestas Infantiles &#128102; &#128103;</h1>
                <p>Llevamos el circo a tu fiesta infantil con divertidos juegos y shows circenses para toda la familia.</p>
                <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasShows"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
            </div>
            <div class="PanelRight PanelRightShows" id="PanelImagen">
                <img src="../../public/5.jpg" class="ImgAbajo">
                <img src="../../public/4P.jpg" class="ImgArriba">
            </div>
        </div>
        <div class="Panel">
            <div class="PanelLeft" id="PanelImagen">
                <img src="../../public/3.jpg" class="ImgAbajo">
                <img src="../../public/5.jpg" class="ImgArriba">
            </div>
            <div class="PanelRight">
                <h1>Payasos &#129313;</h1>
                <p>Espectacular caravana de payasos, hará que tu fiesta sea la más divertida.</p>
                <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasPayasos"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
            </div>
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Magia &#127913;</h1>
                <p>El espectacular mundo del ilusionismo de habilidad e ingenio con este maravilloso show todos quedarán asombrados.</p>
                <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMagia"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
            </div>
            <div class="PanelRight" id="PanelImagen">
                <img src="../../public/5.jpg" class="ImgAbajo">
                <img src="../../public/9.png" class="ImgArriba" id="ImgMagia">
            </div>
        </div>
        <div class="Panel">
            <div class="PanelLeft" id="PanelImagen">
                <img src="../../public/4.jpg" class="ImgAbajo">
                <img src="../../public/1.jpg" class="ImgArriba">
            </div>
            <div class="PanelRight">
                <h1>Mimos &#127917;</h1>
                <p>La finalidad del mimo se podría resumir en una frase: “Hacer visible lo invisible”. Y animar tu fiesta ¡Claro!</p>
                <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMimos"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
            </div>
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Malabares &#129337;</h1>
                <p>El arte de manipular y ejecutar un espectáculo con objetos en el aire, con este grandioso show todos quedarán fascinados.</p>
                <div class="wrap" style="display: block"><span v-on:click="ButtonVerMasMalab"><a style="display: none"></a><a style="display: none"></a><a>Ver más &#128064;</a></span></div>
            </div>
            <div class="PanelRight" id="PanelImagen">
                <img src="../../public/3.jpg" class="ImgAbajo">
                <img src="../../public/10.jpg" class="ImgArriba">
            </div>
        </div>
    </body>
</div>
</template>

<script>
export default {
    name: "Servicios",
    data: function(){  

    },
    methods:{
        ButtonVerMasShows: function() {
            this.$emit('ButtonShowsInf');
        },
        ButtonVerMasMagia: function() {
            this.$emit('ButtonMagia');
        },
        ButtonVerMasPayasos: function() {
            this.$emit('ButtonPayasos');
        },
        ButtonVerMasMimos: function() {
            this.$emit('ButtonMimos');
        },
        ButtonConocenos: function() {
            this.$emit('ButtonConocenos');
        },
        ButtonVerMasMalab: function() {
            this.$emit('ButtonMalabares');
        },
    },
    created: function(){
    },
    destroyed () {
    }
}
</script>

<style>

</style>