import { createRouter, createWebHashHistory } from 'vue-router';
import app from "../App.vue";
import Home from "../views/Home.vue";
import Nosotros from "../views/Nosotros.vue";
import Contactanos from "../views/Contactanos.vue";
import Servicios from "../views/Servicios.vue";
import Payasos from "../views/Payasos.vue";
import FiestasInf from "../views/FiestasInf.vue";
import Magia from "../views/Magia.vue";
import Mimos from "../views/Mimos.vue";
import Malabares from "../views/Malabares.vue";

const routes = [
  {
    path: "/",
    name: "app",
    component: app,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/Nosotros",
    name: "Nosotros",
    component: Nosotros,
  },
  {
    path: "/Contactanos",
    name: "Contactanos",
    component: Contactanos,
  },
  {
    path: "/Servicios",
    name: "Servicios",
    component: Servicios,
  },
  {
    path: "/Payasos",
    name: "Payasos",
    component: Payasos,
  },
  {
    path: "/FiestasInf",
    name: "FiestasInf",
    component: FiestasInf,
  },
  {
    path: "/Magia",
    name: "Magia",
    component: Magia,
  },
  {
    path: "/Mimos",
    name: "Mimos",
    component: Mimos,
  },
  {
    path: "/Malabares",
    name: "Malabares",
    component: Malabares,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
