<template>
<div class="main-component">
    <body>
        <div class="Banner" id="BannerUniqueServ" style="justify-content: center;">
            <img src="../../public/globo.png" class="globo1" id="globo1">
            <img src="../../public/globo3.png" class="globo4" id="globo4">
            <center><video class="VideoPayasos" src="PAYASOS.mp4" width="900" height="450" loop autoplay controls></video></center>
            <img src="../../public/globo.png" class="globo2" id="globo1">
            <img src="../../public/globo2.png" class="globo3" id="globo2"> 
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Payasos &#129313;</h1>
                <p>Espectacular caravana de payasos, hará que tu fiesta sea la más divertida.</p>
                <div class="wrap" id="wrapUnique" style="display: block"><span v-on:click="ButtonContactanos"><a style="display: none"></a><a style="display: none"></a><a>CONTACTANOS!</a></span></div>
            </div>
            <div class="PanelRight">
                <div class="slide">
                    <div class="slide-inner">
                        <input class="slide-open" type="radio" id="slide-1" 
                            name="slide" aria-hidden="true" hidden="" checked="checked">
                        <div class="slide-item">
                            <img src="../../public/payasitas2.jpg">
                        </div>
                        <input class="slide-open" type="radio" id="slide-2" 
                            name="slide" aria-hidden="true" hidden="">
                        <div class="slide-item">
                            <img src="../../public/payaso1.jpg">
                        </div>
                        <input class="slide-open" type="radio" id="slide-3" 
                            name="slide" aria-hidden="true" hidden="">
                        <div class="slide-item">
                            <img src="../../public/6.jpg">
                        </div>
                        <label for="slide-3" class="slide-control prev control-1">‹</label>
                        <label for="slide-2" class="slide-control next control-1">›</label>
                        <label for="slide-1" class="slide-control prev control-2">‹</label>
                        <label for="slide-3" class="slide-control next control-2">›</label>
                        <label for="slide-2" class="slide-control prev control-3">‹</label>
                        <label for="slide-1" class="slide-control next control-3">›</label>
                        <ol class="slide-indicador">
                            <li>
                                <label for="slide-1" class="slide-circulo">•</label>
                            </li>
                            <li>
                                <label for="slide-2" class="slide-circulo">•</label>
                            </li>
                            <li>
                                <label for="slide-3" class="slide-circulo">•</label>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div> 
    </body>
</div>  
</template>

<script>
export default {
    name: "Payasos",
    data: function(){  

    },
    methods:{
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        },
    }
}
</script>

<style>
.VideoPayasos {
    margin: 20px 0px 0px 0px;
    width: 900px; 
    height: 450px;
}
.slide {
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
    width: 400px;
    height: 300px;
}

.slide-inner {
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 300px;
}

.slide-open:checked + .slide-item {
    position: static;
    opacity: 100;
}

.slide-item {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

.slide-item img {
    display: block;
    width: 400px;
    height: 300px;
}

.slide-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
}

.slide-control.prev {
    left: 2%;
}

.slide-control.next {
    right: 2%;
}

.slide-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
}

#slide-1:checked ~ .control-1,
#slide-2:checked ~ .control-2,
#slide-3:checked ~ .control-3 {
    display: block;
}

.slide-indicador {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

.slide-indicador li {
    display: inline-block;
    margin: 0 5px;
}

.slide-circulo {
    color: #828282;
    cursor: pointer;
    display: block;
    font-size: 35px;
}

.slide-circulo:hover {
    color: #aaaaaa;
}

#slide-1:checked ~ .control-1 ~ .slide-indicador 
    li:nth-child(1) .slide-circulo,
#slide-2:checked ~ .control-2 ~ .slide-indicador 
        li:nth-child(2) .slide-circulo,
#slide-3:checked ~ .control-3 ~ .slide-indicador 
        li:nth-child(3) .slide-circulo {
    color: #840404;
}

#titulo {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Open Sans', sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), 
        -1px 0px 2px rgba(255, 255, 255, 0);
}
@media screen and (max-width: 700px) {
    .VideoPayasos {
        width: 80vw;
        height: 60vw;
    }
    #BannerUniqueServ {
        height: 70vw;
    }
    .slide {
        width: 90vw;
        height: 80vw;
    }
    .slide-inner {
        width: 90vw;
        height: 80vw;
    }
    .slide-item img {
        width: 90vw;
        height: 80vw;
    }
    #wrapUnique {
        margin: 0px;
        padding: 0px;
    }
    #wrapUnique span {
        margin: 0px;
        padding: 0px;
    }
    #wrapUnique span a {
        margin: 0px;
        padding: 0px;
    }
}
</style>