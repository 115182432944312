<template>
  <div class="main-component" id="bodyNosotros">
      <body>
            <div class="Banner" id="BannerNosotros">
                <div class="Payaso4container">
                    <img src="../../public/Payaso5.png" class="Payaso5" id="Payaso5" v-on:load="LoadPayaso5Time">
                </div>
                <div class="c-subscribe-box u-align-center" id="BannerNosotrosC">
                    <div class="rainbow"><span></span><span></span></div>
                    <div class="c-subscribe-box__wrapper" id="BannerWrapper">
                        <h3 class="c-subscribe-box__title" id="BannerTittle">¿Quienes somos?</h3>
                        <p class="c-subscribe-box__title" id="BannerTittle">
                        Somos una empresa de tradición circense, con más de 30 años de experiencia en la creación de obras artísticas para el entretenimiento en eventos infantiles y empresariales.
                        Ganadores de grandes festivales nacionales e internacionales en donde hemos sido catalogados como los mejores payasos de Colombia.
                        </p>   
                    </div>
                </div>
                <div class="Payaso3container">
                    <img src="../../public/Payaso3.png" class="Payaso3" id="Payaso3" v-on:load="LoadPayaso3Time">
                </div> 
            </div>
            <div class="Panel">
                <div class="PanelLeft">
                    <h3>Visión</h3>
                    <p>Posicionarnos como una empresa líder e innovadora en la prestación de <b>servicios culturales y shows circenses</b> a través de un espectáculo <b>dinámico y divertido</b> con conciencia social, generando <b>fidelidad, lealtad y satisfacción</b> en nuestros clientes.</p>
                </div>
                <div class="PanelRight">
                    <h3>Misión</h3>
                    <p>Ofrecer el mejor espectáculo de entretenimiento <b>familiar</b> donde los payasos y el mágico mundo del circo acompañan las diferentes <b>reuniones familiares o eventos empresariales</b>, haciendo que <b>chicos y grandes</b> se diviertan y vivan momentos inolvidables.</p>
                </div>
            </div>
            <div class="Panel">
                <div class="wrap" style="display: block"><span v-on:click="ButtonContactanos"><a style="display: none"></a><a style="display: none"></a><a>CONTACTANOS!</a></span></div>
            </div>
        </body>
  </div>
</template>

<script>
export default {
    name: "Nosotros",
    data: function(){  

    },
    methods:{
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        },
        LoadPayaso3: function() {
            if(screen.width > 700){
                document.getElementById("Payaso3").style.transform = ("translateX(0px)");
                document.getElementById("Payaso3").style.opacity = ("1");
            } else {
                document.getElementById("Payaso3").style.opacity = ("1");
            }
        },
        LoadPayaso3Time: function() {
            setTimeout(this.LoadPayaso3, 2000);
        },
        LoadPayaso5Time: function() {
            setTimeout(this.LoadPayaso5, 2000)
        },
        LoadPayaso5: function() {
            if(screen.width > 700){
                document.getElementById("Payaso5").style.transform = ("translateX(-350px)");
                document.getElementById("Payaso5").style.opacity = ("1");
            } else {
                document.getElementById("Payaso5").style.opacity = ("1");
            }
            
        }
    },
    created: function(){
    },
    destroyed () {
    }
}
</script>

<style>
#BannerTittle {
    font-size: 20px;
}
#BannerNosotrosC {
    border-radius: 20px; 
    width: 510px; 
    height: 410px;
}
#BannerWrapper {
    border-radius: 20px; 
    width: 500px; 
    height: 400px;
}
#LogoGifNosotros {
    height: 400px;
    width: 60%;
    border-radius: 0px;
}
#bodyNosotros {
    color: #5e5e5e;
    /* padding: 75px 0px 0px 0px; */
}
#BannerNosotros {
    align-items: center;
    justify-content: center;
}
.Payaso3 {
    z-index: 96;
    margin: 0px 40px;
    top: 180px;
    position: absolute;
    width: 18%;
    transition: width 0.5s, top 0.5s, transform 2s, opacity 2s;
    transform: translateX(350px);
    opacity: 0;
}
.Payaso3:hover {
    top: 150px;
    width: 20%;
}
.Payaso5 {
    z-index: 96;
    margin: 0px 20px;
    top: 200px;
    position: absolute;
    width: 25%;
    transition: width 0.5s, top 0.5s, transform 2s, opacity 2s;
    transform: translateX(-700px);
    opacity: 0;
}
.Payaso5:hover {
    top: 180px;
    width: 27%;
}
@media screen and (max-width: 700px) {
    #BannerNosotrosC {
        width: 80vw;
        height: 80vw;
    }
    #BannerWrapper {
        width: 78vw;
        height: 78vw;
    }
    #BannerTittle {
        font-size: 4vw;
    }
    .Payaso3 {
        margin: 0px;
        top: 165vw;
        width: 40%;
        transition: width 0.5s, top 0.5s, transform 2s, opacity 2s;
        transform: translateX(-120px);
    }
    .Payaso3:hover {
        top: 168vw;
        width: 42%;
    }
    .Payaso5 {
        z-index: 96;
        margin: 0px 20px;
        top: 73vw;
        position: absolute;
        width: 40%;
        transition: width 0.5s, top 0.5s, transform 2s, opacity 2s;
        transform: translateX(-80px);
        opacity: 0;
    }
    .Payaso5:hover {
        top: 74vw;
        width: 42%;
    }
}
</style>