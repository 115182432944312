<template>
<div class="main-component">
    <body>
        <div class="Banner" id="BannerUniqueServ" style="justify-content: center;">
            <img src="../../public/globo.png" class="globo1" id="globo1">
            <img src="../../public/globo3.png" class="globo4" id="globo4">
            <center><video class="VideoPayasos" src="FiestasInf.mp4" loop autoplay controls></video></center>
            <img src="../../public/globo.png" class="globo2" id="globo1">
            <img src="../../public/globo2.png" class="globo3" id="globo2"> 
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Fiestas Infantiles &#128102; &#128103;</h1>
                <p>Llevamos el circo a tu fiesta infantil con divertidos juegos y shows circenses para toda la familia.</p>
                <div class="wrap" id="wrapUnique" style="display: block"><span v-on:click="ButtonContactanos"><a style="display: none"></a><a style="display: none"></a><a>CONTACTANOS!</a></span></div>
            </div>
            <div class="PanelRight">
                <div class="slide">
                    <div class="slide-inner">
                        <input class="slide-open" type="radio" id="slide-1" 
                            name="slide" aria-hidden="true" hidden="" checked="checked">
                        <div class="slide-item">
                            <img src="../../public/4P.jpg">
                        </div>
                        <input class="slide-open" type="radio" id="slide-2" 
                            name="slide" aria-hidden="true" hidden="">
                        <div class="slide-item">
                            <img src="../../public/1P.jpg">
                        </div>
                        <input class="slide-open" type="radio" id="slide-3" 
                            name="slide" aria-hidden="true" hidden="">
                        <div class="slide-item">
                            <img src="../../public/3.jpg">
                        </div>
                        <label for="slide-3" class="slide-control prev control-1">‹</label>
                        <label for="slide-2" class="slide-control next control-1">›</label>
                        <label for="slide-1" class="slide-control prev control-2">‹</label>
                        <label for="slide-3" class="slide-control next control-2">›</label>
                        <label for="slide-2" class="slide-control prev control-3">‹</label>
                        <label for="slide-1" class="slide-control next control-3">›</label>
                        <ol class="slide-indicador">
                            <li>
                                <label for="slide-1" class="slide-circulo">•</label>
                            </li>
                            <li>
                                <label for="slide-2" class="slide-circulo">•</label>
                            </li>
                            <li>
                                <label for="slide-3" class="slide-circulo">•</label>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div> 
    </body>
</div>  
</template>

<script>
export default {
    name: "FiestasInf",
    data: function(){  

    },
    methods:{
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        },
    }
}
</script>

<style>
</style>