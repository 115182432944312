<template>
<div class="main-component">
    <body>
        <div class="Banner" id="BannerUniqueServ" style="justify-content: center;">
            <img src="../../public/globo.png" class="globo1" id="globo1">
            <img src="../../public/globo3.png" class="globo4" id="globo4">
            <center><video class="VideoPayasos" src="MAGIA2.mp4" loop autoplay controls></video></center>
            <img src="../../public/globo.png" class="globo2" id="globo1">
            <img src="../../public/globo2.png" class="globo3" id="globo2"> 
        </div>
        <div class="Panel">
            <div class="PanelLeft">
                <h1>Magia &#127913;</h1>
                    <p>El espectacular mundo del ilusionismo de habilidad e ingenio con este maravilloso show todos quedarán asombrados.</p>
                <div class="wrap" id="wrapUnique" style="display: block"><span v-on:click="ButtonContactanos"><a style="display: none"></a><a style="display: none"></a><a>CONTACTANOS!</a></span></div>
            </div>
            <div class="PanelRight">
                <video src="MAGIA1.mp4" width="400" height="300" controls></video>
            </div>
        </div> 
    </body>
</div>  
</template>

<script>
export default {
    name: "Magia",
    data: function(){  

    },
    methods:{
        ButtonContactanos: function() {
            this.$emit("ButtonContactanos");
        },
    }
}
</script>

<style>
</style>